<template>
  <div>
    <Row type="flex" justify="start">
      <!-- 菜单栏 -->
      <Col span="4">
      <Affix>
        <Menu ref="menu" theme="dark" style="
              width: 100%;
              height: 100vh;
              overflow-y: auto;
              box-shadow: 0px 0px 8px 4px #ccc;
            " :active-name="activeName" :open-names="openNames" accordion @on-select="onSelect" @on-open-change="onOpenChange">
          <div style="
                font-size: 24px;
                padding: 20px;
                color: #fff;
                font-family: Microsoft YaHei;
                letter-spacing: 2pt;
              ">
            {{ title }}
          </div>
          <Submenu :name="item.name" v-for="item in menuList" :key="item.id">
            <template slot="title">
              <Icon :type="item.icon" size="18" />
              {{ item.title }}
            </template>
            <MenuItem :to="child.name" :name="child.name" v-for="child in item.children" :key="child.id">{{ child.title }}</MenuItem>
          </Submenu>
        </Menu>
      </Affix>
      </Col>

      <Col span="20">
      <Affix>
        <Row type="flex" justify="start" style="
              height: 64px;
              box-shadow: 0px 0px 4px 2px #ccc;
              background-color: #fff;
              padding: 20px;
            ">
          <!-- 面包屑 -->
          <Col>
          <Breadcrumb>
            <BreadcrumbItem :to="item.name" v-for="item in $route.meta" :key="item.name">{{ item.title }}</BreadcrumbItem>
          </Breadcrumb>
          </Col>
          <!-- 个人信息 -->
          <Col style="
                position: absolute;
                right: 0px;
                min-width: 100px;
                margin-right: 15px;
              ">
          <Dropdown trigger="click" @on-click="clickDropdown">
            <a href="javascript:void(0)">
              {{ userName }}
              <Icon type="md-settings" />
            </a>
            <DropdownMenu slot="list">
              <DropdownItem name="userInfo">
                <Icon type="md-lock"></Icon>&nbsp;&nbsp;修改密码
              </DropdownItem>
              <DropdownItem divided name="logout">
                <Icon type="md-log-out"></Icon>&nbsp;&nbsp;退出登录
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          </Col>
        </Row>
      </Affix>
      <!--页面  -->
      <div style="padding: 3vh; min-height: calc(100vh - 64px);background: #F8F9FB;">
        <router-view></router-view>
      </div>
      </Col>
    </Row>

    <!-- 修改密码模态框 -->
    <Modal v-model="modalPassword" title="修改密码" :loading="loadingPassword" @on-ok="setPassword()">
      <Form ref="form" :model="formPassword">
        <FormItem>
          <Input type="text" v-model="formPassword.password1" placeholder="请输入密码"></Input>
        </FormItem>
        <FormItem>
          <Input type="text" v-model="formPassword.password2" placeholder="再次输入密码"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //title
      title: this.MyUtil.title,
      //修改密码
      loadingPassword: true,
      modalPassword: false,
      formPassword: { password1: null, password2: null },
      //面包屑
      breadcrumbItem: [],
      //菜单
      menuList: [],
      activeName: "",
      openNames: [],
      //个人信息
      userName: this.getKey("userName"),
    };
  },
  created() {
    this.getMenulist();
  },
  methods: {
    clickDropdown(name) {
      switch (name) {
        case "userInfo":
          this.formPassword = { password1: null, password2: null };
          this.modalPassword = true;
          break;
        case "logout":
          this.MyUtil.ajax()
            .post("/UserController/logout", {})
            .then((resData) => {
              if (null != resData) {
                localStorage.clear();
                this.$router.push({
                  name: "login",
                });
              }
            });
          break;
      }
    },
    getMenulist() {
      this.MyUtil.ajax()
        .post("/UserController/getWebMenuTree", {})
        .then((resData) => {
          if (resData instanceof Array) {
            this.menuList = resData;
            //获取上次展开的菜单和选中的菜单
            let lastOpenNames = this.getKey("openNames");
            let lastActiveName = this.getKey("activeName");
            if (lastOpenNames && lastActiveName) {
              this.openNames = lastOpenNames;
              this.activeName = lastActiveName;
            } else {
              let openName = resData[0].name;
              let activeName = resData[0].children[0].name;
              this.openNames.push(openName);
              this.activeName = activeName;
              this.setKey("openNames", this.openNames);
              this.setKey("activeName", this.activeName);
            }
            this.$nextTick(() => {
              //展开和选中菜单
              this.$refs.menu.updateOpened();
              this.$refs.menu.updateActiveName();
              //加载上次使用的路由
              let lastRouterName = this.getKey("routerName");
              let lastRouterParams = this.getKey("routerParams");
              if (lastRouterName) {
                this.$router.push({
                  name: lastRouterName,
                  params: lastRouterParams,
                });
              } else {
                this.$router.push({
                  name: this.activeName,
                });
              }
            });
          }
        });
    },
    onOpenChange(name) {
      this.setKey("openNames", name);
    },
    onSelect(name) {
      this.setKey("activeName", name);
    },
    updateActiveName() {
      let routeList = this.$route.meta;
      if (routeList instanceof Array && routeList.length >= 2) {
        this.openNames = [];
        this.activeName = null;
        this.$refs.menu.updateOpened();
        this.$refs.menu.updateActiveName();
        this.$nextTick(() => {
          this.openNames.push(routeList[0].name);
          this.activeName = routeList[1].name;
          this.setKey("openNames", this.openNames);
          this.setKey("activeName", this.activeName);
          this.$nextTick(() => {
            this.$refs.menu.updateOpened();
            this.$refs.menu.updateActiveName();
          });
        });
      }
    },

    setPassword() {
      this.loadingPassword = false;
      this.$nextTick(() => {
        this.loadingPassword = true;
      });
      if (!this.formPassword.password1) {
        this.$Message.warning("请输入密码");
        return;
      }
      if (this.formPassword.password1 != this.formPassword.password2) {
        this.$Message.warning("两次输入密码不一致");
        return;
      }
      this.MyUtil.ajax()
        .post("/UserController/setPassword", {
          password: this.formPassword.password1,
        })
        .then((resData) => {
          if (null != resData) {
            this.$Message.success("修改成功");
            this.modalPassword = false;
          }
        });
    },
  },
  watch: {
    $route(to) {
      //记录当前路由和页面传参，避免刷新后页面和参数丢失
      this.setKey("routerName", to.name);
      if (JSON.stringify(to.params) != "{}") {
        this.setKey("routerParams", to.params);
      } else {
        this.setKey("routerParams", null);
      }
    },
  },
};
</script>
